import { all, takeEvery, put, fork, call } from "redux-saga/effects";
import {
  DELETE_MANUAL_REVIEW_DATA_BY_ID,
  GET_MANUAL_REVIEW_DATA,
  GET_MANUAL_REVIEW_DATA_BY_ID,
  GET_MANUAL_REVIEW_FILTER_MAX_VALUES,
} from "redux/constants/ManualReview";

import {
  getManualReviewDataSuccess,
  getManualReviewDataFailed,
  getManualReviewMaxValuesFailed,
  setManualReviewMaxValues,
  getManualReviewDataByIdSuccess,
  getManualReviewDataByIdFailed,
  deleteManualReviewDataByIdSuccess,
  deleteManualReviewDataByIdFailed,
} from "redux/actions/ManualReview";

import SupabaseService from "services/SupabaseService";

export function* getAllManualReviewData() {
  yield takeEvery(GET_MANUAL_REVIEW_DATA, function* () {
    try {
      const { data, error } = yield call(SupabaseService.getAllManualData);
      if (error) throw error;
      else {
        // const manualReviewData = data.map((item) => {
        //   let listings = [];
        //   if (item.manual_review_listings.length > 0) {
        //     listings = item.manual_review_listings.map((item) => {
        //       return { ...item, agents: item.manual_review_agents };
        //     });
        //   }
        //   return { ...item, listings };
        // });

        yield put(getManualReviewDataSuccess(data));
      }
    } catch (error) {
      yield put(getManualReviewDataFailed("Request Failed, Please try again"));
      console.log(error);
    }
  });
}

export function* getAllManualReviewDataById() {
  yield takeEvery(GET_MANUAL_REVIEW_DATA_BY_ID, function* (payload) {
    try {
      const { id } = payload;
      const { data, error } = yield call(SupabaseService.getManualDataById, id);
      if (error) throw error;
      else {
        const manualReviewData = data.map((item) => {
          let property_database_listings = [];
          if (item.manual_review_listings.length > 0) {
            property_database_listings = item.manual_review_listings.map(
              (item) => {
                return {
                  ...item,
                  property_database_agents: item.manual_review_agents,
                };
              }
            );
          }
          return { ...item, property_database_listings };
        });

        yield put(getManualReviewDataByIdSuccess(manualReviewData));
      }
    } catch (error) {
      yield put(
        getManualReviewDataByIdFailed("Request Failed, Please try again")
      );
      console.log(error);
    }
  });
}

export function* deleteManualReviewDataByID() {
  yield takeEvery(DELETE_MANUAL_REVIEW_DATA_BY_ID, function* (payload) {
    try {
      const { id } = payload;
      const { error } = yield call(
        SupabaseService.deleteManualReviewDataByID,
        id
      );
      if (error) throw error;
      else {
        yield put(deleteManualReviewDataByIdSuccess("Deleted successfully"));
      }
    } catch (error) {
      yield put(deleteManualReviewDataByIdFailed("Error, Please try again"));
      console.log(error);
    }
  });
}

export function* getManualReviewFilterMaxValue() {
  yield takeEvery(GET_MANUAL_REVIEW_FILTER_MAX_VALUES, function* () {
    try {
      // const { data, error } = yield call(
      //   SupabaseService.getValuesForManualReviewFilters
      // );
      const data = [];
      const { data: priceData, error: priceError } = yield call(
        SupabaseService.getMRPriceMaxValue
      );
      if (priceError) throw priceError;
      else data.push(priceData[0]);
      const { data: dateMaxData, error: dateMaxError } = yield call(
        SupabaseService.getMRDateAddedMaxValue
      );
      if (dateMaxError) throw dateMaxError;
      else data.push(dateMaxData[0]);
      const { data: dateMinData, error: dateMinError } = yield call(
        SupabaseService.getMRDateAddedMinValue
      );
      if (dateMinError) throw dateMinError;
      else data.push(dateMinData[0]);
      const { data: bedroomsMaxData, error: bedRoomsMaxError } = yield call(
        SupabaseService.getMRBedroomsMaxValue
      );
      if (bedRoomsMaxError) throw bedRoomsMaxError;
      else data.push(bedroomsMaxData[0]);
      const { data: bathroomsMaxData, error: bathroomsMaxError } = yield call(
        SupabaseService.getMRBathroomsMaxValue
      );
      if (bathroomsMaxError) throw bathroomsMaxError;
      else data.push(bathroomsMaxData[0]);

      yield put(setManualReviewMaxValues(data));
    } catch (error) {
      console.log(error);
      yield put(getManualReviewMaxValuesFailed());
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(getAllManualReviewData),
    fork(getAllManualReviewDataById),
    fork(getManualReviewFilterMaxValue),
    fork(deleteManualReviewDataByID),
  ]);
}
