import {
  YEARLY,
  NUMBER_OF_SALES,
  AVERAGE_SALES_PRICE,
  MEDIAN_SALES_PRICE,
  SALES_VOLUME,
  QUARTERLY,
  PRICE_BRACKET,
  REGION_ALL,
  YEAR_OVER_YEAR,
  CATEGORY_ALL,
  BRACKET_ALL,
} from "constants/ReortContentConstants";

import {
  generateChartTwoQuartersArray,
  generateQuartersArray,
  generateYOYArray,
  generateYearsArray,
  getCurrentQuarter,
  getYearOverYearData,
  sumIndexes, medianIndexes,
} from "./utils";

export const chartTwoFilters = (salesData, reportContent) => {
  // console.log("SALES_DATA", salesData);
  // console.log(reportContent);
  const priceIsValid = reportContent.bracketType === PRICE_BRACKET;
  const bracketRangeIsValid = reportContent.bracket !== BRACKET_ALL;
  let priceRange;
  let arvRange;
  if (priceIsValid) {
    priceRange = reportContent.bracket.split("-");
  } else {
    arvRange = reportContent.bracket.split("-");
  }
  let locations;

  locations = reportContent.location.split(",");
  // console.log("locations", locations);

  if (
    reportContent.timePeriod === YEARLY ||
    reportContent.timePeriod === YEAR_OVER_YEAR
  ) {
    const years = generateYearsArray(
      Number(reportContent.fromDate),
      Number(reportContent.toDate)
    );

    //eslint-disable-next-line
    const data = locations.map((l) => {
      // let priceVolume = 1;
      let countArr = [];
      let averageArr = [];
      let medianArr = [];
      let priceToCalMedianArr = [];
      let volumeArr = [];

      for (let year = 0; year < years.length; year++) {
        let count = 0;
        let priceCounter = 0;
        let priceArr = [];
        let median = 0;

        for (let sale = 0; sale < salesData.length; sale++) {
          const salesYear = new Date(salesData[sale].registration_date).getFullYear();

          if (
            salesData[sale]?.parish.toLowerCase() === l.toLowerCase() &&
            (reportContent.category !== CATEGORY_ALL
              ? salesData[sale]?.property_type.toLowerCase() === reportContent.category.toLowerCase()
              : true) &&
            (bracketRangeIsValid
              ? priceIsValid
                ? salesData[sale]?.price
                  ? salesData[sale].price >= Number(priceRange[0]) && salesData[sale].price < Number(priceRange[1])
                  : false
                : salesData[sale]?.combined_arv
                  ? salesData[sale].combined_arv >= Number(arvRange[0]) && salesData[sale].combined_arv < Number(arvRange[1])
                  : false
              : true) &&
            salesYear === Number(years[year])
          ) {
            count += 1;
            priceCounter += salesData[sale].price;
            priceArr.push(salesData[sale].price);
          }
        }

        countArr.push(count);
        averageArr.push(count ? Math.round(priceCounter / count) : 0);

        const priceArrSorted = priceArr.sort((a, b) => a - b);
        if (priceArr.length > 0) {
          const mid = Math.floor(priceArr.length / 2);
          median = priceArr.length % 2 === 0
            ? (priceArrSorted[mid - 1] + priceArrSorted[mid]) / 2
            : priceArrSorted[mid];
        }
        medianArr.push(median);
        priceToCalMedianArr.push(priceArr);

        const priceVolume = priceArr.reduce((acc, price) => acc + price, 0);
        volumeArr.push(Math.floor(priceVolume));
      }

      return { countArr, averageArr, medianArr, volumeArr, priceToCalMedianArr };
    });

    if (reportContent.location === REGION_ALL) {
      // console.log("Region_data", data);
      let result = [];
      let west = data.slice(0, 3);
      let central = data.slice(3, 6);
      let east = data.slice(6, 9);

      const processRegion = (regionData) => {
        if (reportContent.indicator === NUMBER_OF_SALES) {
          return sumIndexes(regionData.map(item => item.countArr)).flat();
        } else if (reportContent.indicator === AVERAGE_SALES_PRICE) {
          const totalVolumeArray = sumIndexes(regionData.map(item => item.volumeArr)).flat();
          const totalCountArray = sumIndexes(regionData.map(item => item.countArr)).flat();
          return totalVolumeArray.map((volume, index) => {
            const count = totalCountArray[index] || 0;
            return count ? Math.round(volume / count) : 0;
          });
        } else if (reportContent.indicator === MEDIAN_SALES_PRICE) {
          return medianIndexes(regionData.map(item => item.priceToCalMedianArr)).flat();
        } else if (reportContent.indicator === SALES_VOLUME) {
          return sumIndexes(regionData.map(item => item.volumeArr)).flat();
        }
      };

      result.push(processRegion(west));
      result.push(processRegion(central));
      result.push(processRegion(east));

      if (reportContent.timePeriod === YEARLY) {
        // console.log("Region_result", result);
        return { data: result, years };
      } else if (reportContent.timePeriod === YEAR_OVER_YEAR) {
        const yearOverYearData = getYearOverYearData(result);
        // console.log("yearOveryear_REGION", yearOverYearData);
        return {
          data: yearOverYearData,
          years: generateYOYArray(
            Number(reportContent.fromDate),
            Number(reportContent.toDate)
          ),
        };
      }
    } else {
      let result = data;
      if (reportContent.indicator === NUMBER_OF_SALES) {
        result = data.map(item => item.countArr);
      } else if (reportContent.indicator === AVERAGE_SALES_PRICE) {
        result = data.map(item => item.averageArr);
      } else if (reportContent.indicator === MEDIAN_SALES_PRICE) {
        result = data.map(item => item.medianArr);
      } else if (reportContent.indicator === SALES_VOLUME) {
        result = data.map(item => item.volumeArr);
      }

      if (reportContent.timePeriod === YEARLY) {
        // console.log("parish_yearly", data);
        return { data: result, years };
      } else if (reportContent.timePeriod === YEAR_OVER_YEAR) {
        // console.log("parish_yearly", data);
        const yearOverYearData = getYearOverYearData(result);

        // console.log("yearOverYear_PARISH", yearOverYearData);
        return {
          data: yearOverYearData,
          years: generateYOYArray(
            Number(reportContent.fromDate),
            Number(reportContent.toDate)
          ),
        };
      }
    }
  } else if (reportContent.timePeriod === QUARTERLY) {
    const years = generateYearsArray(
      Number(reportContent.fromDate),
      Number(reportContent.toDate)
    );
    let quarters;
    const quartersLabels = generateChartTwoQuartersArray(
      Number(reportContent.fromDate),
      Number(reportContent.toDate)
    );
    //eslint-disable-next-line
    const data = locations.map((l) => {
      let countArr = [];
      let averageArr = [];
      let medianArr = [];
      let volumeArr = [];

      for (let y = 0; y < years.length; y++) {
        quarters = generateQuartersArray(years[y]);
        let yearCountArr = [];
        let yearAverageArr = [];
        let yearMedianArr = [];
        let yearVolumeArr = [];

        for (let quarter = 0; quarter < quarters.length; quarter++) {
          let count = 0;
          let priceCounter = 0;
          let average = 0;
          let priceArr = [];
          let median = 0;
          for (let sale = 0; sale < salesData.length; sale++) {
            const salesYear = new Date(
              salesData[sale].registration_date
            ).getFullYear();
            const currentQuarter = getCurrentQuarter(
              new Date(salesData[sale].registration_date)
            );

            if (
              (reportContent.category !== CATEGORY_ALL
                ? salesData[sale].property_type.toLowerCase() ===
                  reportContent.category.toLowerCase()
                : true) &&
              salesData[sale].parish.toLowerCase() === l.toLowerCase() &&
              (bracketRangeIsValid
                ? priceIsValid
                  ? salesData[sale].price
                    ? salesData[sale].price >= Number(priceRange[0]) &&
                      salesData[sale].price < Number(priceRange[1])
                    : false
                  : salesData[sale].combined_arv
                  ? salesData[sale].combined_arv >= Number(arvRange[0]) &&
                    salesData[sale].combined_arv < Number(arvRange[1])
                  : false
                : true) &&
              currentQuarter === quarter + 1 &&
              salesYear === years[y]
            ) {
              count = count + 1;
              priceCounter = priceCounter + salesData[sale].price;
              priceArr.push(salesData[sale].price);
            }
          }

          yearCountArr.push(count);

          average = Math.round(priceCounter / count);
          yearAverageArr.push(average);

          const mid = Math.floor(priceArr.length / 2);
          const priceArrSorted = priceArr.sort((a, b) => a - b);
          if (priceArr.length % 2 === 0) {
            median = (priceArrSorted[mid - 1] + priceArrSorted[mid]) / 2;
            yearMedianArr.push(median);
          } else {
            median = priceArrSorted[mid];
            yearMedianArr.push(median);
          }

          let priceVolume = 0;
          for (let i = 0; i < priceArr.length; i++) {
            priceVolume = priceVolume + priceArr[i];
          }
          yearVolumeArr.push(Math.floor(priceVolume));
        }

        countArr.push(...yearCountArr);
        averageArr.push(...yearAverageArr);
        medianArr = [...medianArr, ...yearMedianArr];
        volumeArr = [...volumeArr, ...yearVolumeArr];
      }

      return {countArr, averageArr, medianArr, volumeArr};
    });
    if (reportContent.location === REGION_ALL) {
      let result = [];
      let west = data.slice(0, 3);
      let central = data.slice(3, 6);
      let east = data.slice(6, 9);

      const processRegion = (regionData) => {
        if (reportContent.indicator === NUMBER_OF_SALES) {
          return sumIndexes(regionData.map(item => item.countArr)).flat();
        } else if (reportContent.indicator === AVERAGE_SALES_PRICE) {
          const totalVolumeArray = sumIndexes(regionData.map(item => item.volumeArr)).flat();
          const totalCountArray = sumIndexes(regionData.map(item => item.countArr)).flat();
          return totalVolumeArray.map((volume, index) => {
            const count = totalCountArray[index] || 0;
            return count ? Math.round(volume / count) : 0;
          });
        } else if (reportContent.indicator === MEDIAN_SALES_PRICE) {
          return medianIndexes(regionData.map(item => item.priceToCalMedianArr)).flat();
        } else if (reportContent.indicator === SALES_VOLUME) {
          return sumIndexes(regionData.map(item => item.volumeArr)).flat();
        }
      };

      result.push(processRegion(west));
      result.push(processRegion(central));
      result.push(processRegion(east));

      // console.log("quarterly_Region", result);
      return { data: result, years: quartersLabels };
    } else {
      let result = data;
      if (reportContent.indicator === NUMBER_OF_SALES) {
        result = data.map(item => item.countArr);
      } else if (reportContent.indicator === AVERAGE_SALES_PRICE) {
        result = data.map(item => item.averageArr);
      } else if (reportContent.indicator === MEDIAN_SALES_PRICE) {
        result = data.map(item => item.medianArr);
      } else if (reportContent.indicator === SALES_VOLUME) {
        result = data.map(item => item.volumeArr);
      }

      if (reportContent.timePeriod === QUARTERLY) {
        // console.log("quarterly_Parishes", data);
        return { data: result, years: quartersLabels };
      }
    }
  }
};
