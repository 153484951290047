import { createContext, useState, useEffect } from "react";
import { supabase } from "auth/SupabaseClient";
import { supabase_authenticated } from "redux/actions/Auth";
import {useDispatch} from "react-redux";
import {ThreeDots} from "react-loader-spinner";

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchSession = async () => {
      const session = await supabase.auth.session();
      dispatch(supabase_authenticated(session));
      setLoading(false);
    };

    fetchSession();

    const { data: authListener } = supabase.auth.onAuthStateChange(
      (event, session) => {
        dispatch(supabase_authenticated(session));
      }
    );

    return () => {
      authListener.unsubscribe();
    };
  }, [dispatch]);

  if (loading) {
    return (
      <div style={{
        position: 'fixed',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
      }}>
        <ThreeDots
          height="80"
          width="80"
          radius="9"
          color="#3e79f7"
          ariaLabel="three-dots-loading"
          wrapperStyle={{
            justifyContent: "center"
          }}
          visible={true}
        />
      </div>
    );
  }

  return (
    <AuthContext.Provider value={{ loading }}>
      {children}
    </AuthContext.Provider>
  );
};