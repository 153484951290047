// src/components/PeriodicFetcher.js
import { useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import {setData} from "../redux/actions/PropertyDatabase";
import { CACHE_KEY, CACHE_NAME } from "../constants/Cache";
import SupabaseService from "../services/SupabaseService"; // Import API service

const CACHE_TIMESTAMP_KEY = "lastFetchTime";
const CACHE_REFRESH_INTERVAL = 1000 * 60;

function PeriodicFetcher() {
  const dispatch = useDispatch();
  const isFetching = useRef(false); // Track if a fetch is currently in progress

  useEffect(() => {
    const fetchDataAndUpdateCache = async () => {
      if (isFetching.current) {
        console.log("Fetch in progress, skipping this interval.");
        return;
      }

      isFetching.current = true; // Set fetching state to true
      try {
        // Open the cache
        const cache = await caches.open(CACHE_NAME);

        // Fetch new data from the API
        const { data, error } = await SupabaseService.fetchStreamingData();
        if (error) throw error;

        // Update cache with the new data
        await cache.put(CACHE_KEY, new Response(JSON.stringify(data)));
        // Update cache timestamp
        const now = Date.now();
        await cache.put(CACHE_TIMESTAMP_KEY, new Response(now.toString()));

        console.log("Data fetched and cache updated:", data.length);

        // Dispatch updated data to Redux
        dispatch(setData(data));
      } catch (error) {
        console.error("Periodic fetch error:", error);
      } finally {
        isFetching.current = false; // Reset fetching state
      }
    };

    // Set an interval to call fetchDataAndUpdateCache
    const intervalId = setInterval(fetchDataAndUpdateCache, CACHE_REFRESH_INTERVAL);

    // Cleanup interval on unmount
    return () => clearInterval(intervalId);
  }, [dispatch]);

  return null; // No UI rendering
}

export default PeriodicFetcher;